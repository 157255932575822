/* @import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500&family=Barlow:wght@600&family=Montserrat:wght@600&display=swap"); */
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url(./assets/font/static/Montserrat-Thin.ttf);
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url(./assets/font/static/Montserrat-ExtraLight.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url(./assets/font/static/Montserrat-Light.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url(./assets/font/static/Montserrat-Regular.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url(./assets/font/static/Montserrat-Medium.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url(./assets/font/static/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url(./assets/font/static/Montserrat-Bold.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url(./assets/font/static/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url(./assets/font/static/Montserrat-Black.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3;
}

.MuiTypography-root{
  font-family: "Montserrat", sans-serif !important;
}

.MuiTableCell-root{
  font-family: "Montserrat", sans-serif !important;
}

.MuiButton-root{
  font-family: "Montserrat", sans-serif !important;
}

.select, .option{
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer-content{
  height: 50px;
  width:
}

.overview-paper{
  /* width: 100%; */
  padding: 16px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.12) 0px 10px 30px -8px, rgba(0, 0, 0, 0.1) 0px 10px 20px -10px, rgba(10, 37, 64, 0.15) 0px -2px 6px 0px inset !important;  
  border-radius: 12px !important;  
  /* background-color: #fff8e1 !important; */
  /* background-image: linear-gradient(to right, rgba(255, 206, 72, 0.5), rgba(255, 206, 72, 1)) !important; */
  background-image: url("./assets/images/pb-bg-calm.svg");
  background-repeat: repeat;
  background-size: cover ;
  text-decoration: none;
}
.overview-paper:hover{
  padding: 16px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.30) 0px 10px 30px -8px, rgba(0, 0, 0, 0.3) 0px 10px 20px -10px, rgba(10, 37, 64, 0.30) 0px -2px 6px 0px inset !important;    
  border-radius: 12px !important;
}
.overview-paper-secondary{
  padding: 16px;
  border-radius: 12px !important;  
  background-image: url("./assets/images/pb-bg-calm.svg");
  background-repeat: repeat;
  background-size: cover ;
  text-decoration: none;
}
.boxhead{
  text-decoration: none;
}

.overview-header {
  margin-top: 0px;
  margin-bottom: 8px;
  font-size: 22px;
  color: #8d8d8d;
}
.overview-main-value {
  font-weight: bold;
  font-size: 26px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.overview-secondary-value {
  font-size: 16px;
  color: #BDBDBD;
}
.overview-title-value{
  font-size: 12px;
  color: gray;
  margin-top: 12px;
  margin-bottom: 0px;
}
.overview-paper-card{
  text-align: center;
  background-color: rgba(255,255,255,0.5);
  padding: 12px 8px;
  /* border: 2px solid lightgray; */
  border-radius: 12px;
}
.overview-note{
  font-size: 11px;
  color: #BDBDBD;
  font-weight: 400;
}

.overview-filter{
  text-align: right;
}
.overview-datepicker{
  display: inline-block;
}
.overview-datepicker input{
  font-size: 12px;
  height: 20px;
  border: 2px solid #757575;
  border-radius: 6px;
  background-color: transparent;
  width: 120px;
  margin: 4px;
}

.overview-toggle{
  cursor: pointer;
  display: inline-block;
  border: 2px solid #757575;
  border-radius: 6px;
  font-size: 12px;
  line-height: 22px;
  height: 22px;
  margin: 4px;
  text-align: center;
}
.overview-filter-group{
  display: inline-block;
  margin: 0px 4px 4px 4px;
}
.overview-filter-group span{
  font-size: 8px;
}
.overview-filter-select{
  cursor: pointer;
  display: inline-block;
  border: 2px solid #757575;
  border-radius: 6px;
  font-size: 12px;
  line-height: 22px;
  height: 26px;
  margin: 4px;
  width: 150px;
}

.overview-toggle div{
  display: inline-block;
  width: 60px;
  border-radius: 3px;
}

.overview-toggle div.active{
  background-color: rgb(240, 101, 36);
  color: white;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.overview-detail-header{
  background-color:#f5f5f5;
position:fixed;
width:calc(100% - 270px);
margin-left: -20px;
padding-left:20px;
z-index:1001
}

@media screen and (max-width: 1200px){
  .overview-detail-header{
      width:calc(100% - 20px);
  }
}

.switch-button{
  display:inline-block;
  border:1px #04AA6D solid;
  padding:4px;
  width:70px;
  text-align:center;
  cursor: pointer;
  color:#04AA6D;
  background-color:white
}

.switch-button-left{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.switch-button-right{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}


.tabel-laporan {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tabel-laporan td, .tabel-laporan th {
  border: 1px solid #ddd;
  padding: 8px;
}

.tabel-laporan tr:nth-child(even){background-color: #f2f2f2;}

.tabel-laporan tr:hover {background-color: #ddd;}

.tabel-laporan th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

.table-rekap-footer th {
  background-color: rgba(4, 170, 109, 0.5);
}

.download-table-xls-button{
  border:#04AA6D 1px solid;
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}