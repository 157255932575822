.SideNav {
  background-color: #ffffff;
  flex: none;
  height: 100%;
  font-size: 13px;
  width: 240px;
  position: fixed;
  padding: 5px;
}

.Side-menu-list-item-active {
  border-radius: 5px !important;
  background-color: #f3f3f3 !important;
}

@media only screen and (max-width: 1200px) {
  .SideNav {
    display: none;
    position: relative;
  }
}
