.header-style {
  display: flex;
  justify-content: space-between;
  font-size: 16;
  font-weight: 500;
  color:black;
}

.filter-tabel-exco-content {
  display: inline-block;
  margin-right: 16px;
}

.filter-tabel-exco-content p {
  font-size: 14px;
  margin-bottom: 4px;
  color: #757575;
}

.filter-tabel-exco-content select {
  width: 150px;
  font-size: 13px;
  padding: 6px 3px;
  border-color: #757575;
  border-radius: 4px;
  color: #757575;
}

.responsive-mobile {
  display: flex;
  flex-direction: row;
}

.menu-bar {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .responsive-mobile {
    display: none;
  }

  .menu-bar {
    display: block;
    cursor: pointer;
  }
}
