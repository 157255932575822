.content {
  /* width: 100%; */
  margin-left: 250px;
  /* overflow: auto; */
  /* z-index: 1; */
}

@media only screen and (max-width: 1200px) {
  .content {
    width: 100%;
    margin-left: 0px;
    /* overflow: auto; */
    /* z-index: 1; */
  }
}
